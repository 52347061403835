<template>
  <!-- Advertisement Media Content -->
  <div
    class="flex flex-col group"
    :style="
      advertisement.publisher_platform?.includes('landing page')
        ? 'height: 550px;'
        : ''
    "
    :class="[
      {
        'h-96 overflow-y-scroll':
          advertisement.publisher_platform?.includes('landing page'),
      },
      { 'small-width  mx-auto bg-background-hover': shrinkMedia },
      { 'relative': ['dco', 'dpa'].includes(advertisement.type) }
    ]"
  >

    <!-- Dynamic Indicator -->
    <div
      v-if="advertisement.type === 'dco' || advertisement.type === 'dpa'"
      class="absolute top-0 flex w-full justify-start cursor-default transition-opacity opacity-0 group-hover:opacity-100"
    >
      <div class="z-10 flex items-center gap-1 text-white bg-black bg-opacity-30 w-max py-1 px-1.5 m-3 rounded cursor-none pointer-events-none">
        <DynamicFormatIcon
          :width="15"
          :height="15"
          stroke="#fff"
          class="pointer-events-none"
        />
        <BaseText
          type="label"
          size="xs"
          class="pointer-events-none"
        >
          Dynamic Ad ({{ carouselIndex + 1 }}/{{ advertisement.cards.length }})
        </BaseText>
      </div>
    </div>

    <img
      v-if="advertisement.type === 'image' && !showError"
      ref="media"
      alt="Image"
      :src="advertisement.image"
      class="w-full"
      :class="[
        advertisement.publisher_platform?.includes('landing page')
          ? ''
          : 'object-contain',
        { 'rounded-lg': rounded },
      ]"
      @load="$emit('loaded-content')"
      @error="showError = true"
    >

    <!-- Image Error -->
    <div
      v-else-if="
        (advertisement.type === 'image' && !advertisement) || showError
      "
      class="flex flex-col items-center justify-center h-full w-full"
      :class="{'rounded-lg': rounded}"
      style="min-height: 400px"
    >
      <i class="fas fa-unlink text-2xl mb-2" />

      Broken image
    </div>

    <!-- Thumbnail -->
    <div
      v-else-if="
        showThumbnail &&
          advertisement.type === 'video' &&
          advertisement.thumbnail &&
          !advertisement.thumbnail.includes('undefined') &&
          !safariBrowser
      "
      class="relative cursor-pointer"
      @mouseover="showThumbnail = false"
    >
      <img
        ref="media"
        alt="Image thumbnail"
        :src="advertisement.thumbnail"
        class="w-full"
        :class="[
          advertisement.publisher_platform?.includes('landing page')
            ? ''
            : 'object-contain',
          { 'rounded-lg': rounded },
        ]"
        @load="$emit('loaded-content')"
      >
      <div
        v-if="
          showThumbnail &&
            advertisement.type === 'video' &&
            advertisement.thumbnail
        "
        class="absolute inset-0 flex items-center justify-center"
      >
        <div
          class="bg-black h-14 w-14 flex justify-center items-center rounded-full box-border absolute bg-opacity-30"
        >
          <img
            src="../../assets/icons/play-icon.svg"
            alt=""
            class="w-5 h-5 pl-0.5"
          >
        </div>
      </div>
    </div>
    <!-- Video Player (safari) -->
    <div
      v-else-if="advertisement.type === 'video' && safariBrowser"
      class="relative overflow-hidden flex cursor-pointer group"
    >
      <video
        ref="videoPlayer"
        :poster="
          advertisement.thumbnail &&
            !advertisement.thumbnail.includes('undefined') &&
            advertisement.thumbnail
        "
        controls
        preload="metadata"
        class="object-contain w-full h-full cursor-pointer"
        :class="{ 'rounded-lg': rounded }"
        @timeupdate="updateVariable"
        @loadeddata="$emit('loaded-content')"
        @play="() => SET_AD_PLAYING(advertisement.id)"
      >
        <source
          :src="advertisement.video"
          type="video/mp4"
        >
      </video>
    </div>
    <!-- Video -->
    <div
      v-else-if="advertisement.type === 'video'"
      class="relative overflow-hidden flex cursor-pointer group"
      @click.stop.prevent="() => {}"
      @mouseenter="hoverScrub(), resize()"
      @mouseleave="
        (focused = false),
        (showControls = true),
        (hideScrollBar = false),
        getAdPlaying !== advertisement.id ? (hidePlay = false) : ''
      "
    >
      <video
        ref="videoPlayer"
        :poster="
          advertisement.thumbnail &&
            !advertisement.thumbnail.includes('undefined') &&
            advertisement.thumbnail
        "
        :controls="showControls && !hideScrollBar && hidePlay"
        preload="metadata"
        class="object-contain w-full h-full cursor-pointer"
        :class="{ 'rounded-lg': rounded }"
        @timeupdate="updateVariable"
        @loadeddata="$emit('loaded-content')"
        @click="togglePlayPause()"
      >
        <source
          :src="advertisement.video"
          type="video/mp4"
        >
      </video>
      <div
        v-if="!hidePlay"
        class="absolute inset-0 flex items-center justify-center select-none"
      >
        <div
          class="bg-black h-14 w-14 flex justify-center items-center rounded-full box-border absolute bg-opacity-30"
        >
          <img
            src="../../assets/icons/play-icon.svg"
            alt=""
            class="w-5 h-5 pl-0.5"
          >
        </div>
      </div>
      <!-- Time Stamp -->
      <div
        v-if="currentTime"
        class="absolute flex w-full justify-end cursor-default transition-opacity duration-300 hover:opacity-100 opacity-0 group-hover:opacity-100 pointer-events-none"
        :class="[{ 'opacity-100': scrubbing }]"
      >
        <BaseText
          type="label"
          size="xs"
          class="text-white text-xs w-max py-1 px-1.5 m-3 z-10 rounded bg-black bg-opacity-30 cursor-none hidden md:block pointer-events-none"
        >
          {{ currentTime }}
        </BaseText>
      </div>
      <div
        v-if="hideScrollBar"
        class="w-full h-full flex justify-center absolute bottom-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        @click.stop.prevent="togglePlayPause()"
      >
        <div
          class="w-11/12 h-full flex"
        >
          <div
            class="absolute bottom-0 h-full cursor-pointer bg-white bg-transparent bg-opacity-0 w-11/12"
          >
            <!-- Line -->
            <div
              v-if="scrubbing"
              class="absolute bottom-0 h-full w-0.5 object-contain cursor-pointer bg-white ml-3.5"
              :class="scrubbing ? 'opacity-100' : 'opacity-0'"
              :style="`left:${linePosition * 87 + 1.5}%;`"
            />
          </div>
          <!-- Scroll Bar -->
          <div
            class="w-full h-10 rounded-md flex items-center bg-black bg-opacity-10 cursor-pointer bottom-0 mb-4 self-end overflow-hidden"
            style="
              background: rgba(0, 0, 0, 0.3);
              backdrop-filter: blur(2px);
              cursor: none;
            "
            @click.stop.prevent="clickScrub"
            @mouseleave="leaveScrub()"
            @mouseenter="scrubbing = true"
          >
            <div
              class="w-full absolute inset-0 flex items-center justify-center transition-opacity duration-300 bottom-0"
              :class="scrubbing ? 'opacity-10' : 'opacity-100'"
              style="cursor: none"
            >
              <BaseText
                type="heading"
                size="overline"
                class="z-10 text-white text-opacity-60"
                style="cursor: none"
              >
                HOVER SCRUB
              </BaseText>
            </div>
            <!-- Play Button -->
            <div
              class="absolute left-3.5 inset-0 cursor-pointer z-30 w-11/12"
              style="cursor: none"
              @mousemove="scrubbing && updateTime($event)"
              @click="clickScrub"
            />
            <img
              src="../../assets/icons/scrub.svg"
              style="cursor: none"
              alt=""
              class="h-2.5 z-20 opacity-0 absolute"
              :class="scrubbing ? 'opacity-100' : 'opacity-0'"
              :style="`left:${linePosition * 87 + 1.5}%;`"
            >
          </div>
        </div>
      </div>
    </div>
    <!-- Carousel -->
    <BaseCarousel
      v-else
      :items="advertisement.cards"
      :carousel-index.sync="carouselIndex"
      :adjust-asset-height-on-change="inAdDetailsDrawer"
      @assetResized="$emit('assetResized')"
      rounded
      ref="carousel"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClickOutside from 'vue-click-outside'
import debounce from 'lodash/debounce'
import DynamicFormatIcon from '../globals/Icons/FilterIcons/DynamicFormatIcon.vue'

export default {
  name: 'AdvertisementMediaContent',
  components: {
    DynamicFormatIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    shrinkMedia: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    inAdDetailsDrawer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showError: false,
      carouselIndex: 0,
      showThumbnail: true,
      scrubbed: 0,
      loaded: 0,
      cursorPosition: 0,
      linePosition: 0,
      hideLine: true,
      scrollable: true,
      focused: false,
      hidePlay: false,
      currentPercentage: 0,
      hideScrollBar: false,
      scrubbing: false,
      currentTime: '',
      showControls: false,
      safariBrowser: false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('AdvertisementsModule', ['getAdPlaying'])
  },
  watch: {
    carouselIndex () {
      this.$emit('update:carousel-index', this.carouselIndex)
      this.$nextTick(() => {
        this.resize()
      })
    },
    getAdPlaying (newValue) {
      if (newValue !== this.advertisement.id) {
        this.reset()
      }
    },
    currentTime (newValue) {
      if (newValue === '0:00 / 0NaN:0NaN') {
        this.currentTime = '0:00 / 0:00'
      }
    }
  },
  mounted () {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      this.safariBrowser = true
      this.showThumbnail = false
    }
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_AD_PLAYING']),
    updateVariable: debounce(function (event) {
      if (!this.$refs.videoPlayer) return
      const video = event.target
      const currentTime = Math.floor(video.currentTime)
      const duration = Math.floor(video.duration)

      if (this.currentTime === '0:0-1 / 0:00') {
        this.currentTime = '0:00 / 0:00'
      } else if (duration < 60) {
        const formattedCurrentTime =
          currentTime >= 10 ? currentTime : '0' + currentTime
        const formattedDuration = duration >= 10 ? duration : '0' + duration
        this.currentTime = `0:${formattedCurrentTime} / 0:${formattedDuration}`
      } else {
        const minutes = Math.floor(duration / 60)
        const seconds = duration % 60
        const formattedDuration = `${minutes >= 10 ? minutes : '0' + minutes}:${
          seconds >= 10 ? seconds : '0' + seconds
        }`

        if (currentTime >= 60) {
          const currentMinutes = Math.floor(currentTime / 60)
          const currentSeconds = currentTime % 60
          const formattedCurrentTime = `${
            currentMinutes >= 10 ? currentMinutes : '0' + currentMinutes
          }:${currentSeconds >= 10 ? currentSeconds : '0' + currentSeconds}`
          this.currentTime = `${formattedCurrentTime} / ${formattedDuration}`
        } else {
          const formattedCurrentTime =
            currentTime >= 10 ? currentTime : '0' + currentTime
          this.currentTime = `0:${formattedCurrentTime} / ${formattedDuration}`
        }
      }
    }, 1),
    updateTime (event) {
      if (!this.$refs.videoPlayer) return
      const video = this.$refs.videoPlayer
      const rect = event.target.getBoundingClientRect()
      const x = event.clientX - rect.left
      const percent = x / rect.width
      this.linePosition = percent

      if (video.paused) {
        const frameRate = 60
        const intervalFrames = Math.floor(frameRate / 10)
        let currentFrame = 0
        let newTime = 0
        let loadedMetadata = false

        // Listen for the "loadedmetadata" event to know when the video's duration is available
        video.addEventListener('loadedmetadata', () => {
          loadedMetadata = true
          currentFrame = Math.floor(video.duration * percent * frameRate)
          const newFrame =
            Math.floor(currentFrame / intervalFrames) * intervalFrames
          newTime = newFrame / frameRate
          video.currentTime = newTime
          this.currentTime = this.formatTime(newTime)
        })

        // If the metadata has already loaded, update the video's current time immediately
        if (video.duration && video.duration > 0) {
          loadedMetadata = true
          currentFrame = Math.floor(video.duration * percent * frameRate)
          const newFrame =
            Math.floor(currentFrame / intervalFrames) * intervalFrames
          newTime = newFrame / frameRate
          video.currentTime = newTime
          newTime = isNaN(newTime) ? 0 : newTime
          this.currentTime = this.formatTime(newTime)
        }
        // If the metadata hasn't loaded yet, update the video's current time as soon as it's available
        const checkMetadataInterval = setInterval(() => {
          if (loadedMetadata) {
            clearInterval(checkMetadataInterval)
            currentFrame = Math.floor(video.duration * percent * frameRate)
            const newFrame =
              Math.floor(currentFrame / intervalFrames) * intervalFrames
            newTime = newFrame / frameRate
            video.currentTime = newTime
            this.currentTime = this.formatTime(newTime)
          }
        }, 50)
      }
    },
    resize () {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    async togglePlayPause () {
      try {
        if (this.$refs.videoPlayer && this.$refs.videoPlayer !== undefined) {
          this.SET_AD_PLAYING(this.advertisement.id)
          const video = this.$refs.videoPlayer
          if (video?.paused) {
            setTimeout(() => {
              video.play()
              this.focused = true
              this.hidePlay = true
              this.hideScrollBar = false
              this.scrollable = false
            }, 100)
          } else {
            video.pause()
          }
        }
      } catch (err) {
        console.log(this.$refs.videoPlayer.paused)
        console.log(err)
      }
    },
    playVideoAtTimestamp (time) {
      // This func is called directly on the component instance from a parent
      if (this.advertisement?.type === 'video' && this.advertisement.video) {
        this.showThumbnail = false
        this.$nextTick(() => {
          const video = this.$refs.videoPlayer
          video.currentTime = time
          if (video.paused) this.togglePlayPause()
        })
      } else if (['carousel', 'dco'].includes(this.advertisement?.type) && this.advertisement?.cards?.[this.carouselIndex]?.video) {
        const carousel = this.$refs.carousel
        carousel.playVideoAtTimestamp(time)
      }
    },
    hoverScrub () {
      if (this.getAdPlaying !== this.advertisement.id) {
        this.hideScrollBar = true
        this.scrollable = true
      }
      if (this.getAdPlaying === this.advertisement.id) {
        this.showControls = true
      }
    },
    async clickScrub () {
      try {
        if (!this.$refs.videoPlayer) return
        this.SET_AD_PLAYING(this.advertisement.id)
        const video = this.$refs?.videoPlayer
        if (video.paused) {
          video.play()
        }
      } catch (err) {
        console.log(err)
      }
      this.hidePlay = true
      this.hideScrollBar = false
      this.focused = true
      this.scrubbing = false
    },
    async leaveScrub () {
      try {
        if (!this.$refs.videoPlayer) return
        await this.$refs?.videoPlayer?.pause()
        setTimeout(() => {
          this.scrollable = false
          this.hideScrollBar = true
          this.$refs.videoPlayer.currentTime = 0
          this.scrubbed = 0
          this.cursorPosition = 0
          this.linePosition = 0
          this.hideLine = true
          this.currentPercentage = 0
          this.scrubbing = false
          this.currentTime = ''
        }, 100)
      } catch (err) {
        console.log(err)
      }
    },
    async reset () {
      try {
        if (!this.$refs.videoPlayer) return
        this.showError = false
        this.showThumbnail = true
        this.scrubbed = 0
        this.cursorPosition = 0
        this.linePosition = 0
        this.hideLine = true
        this.scrollable = true
        this.focused = false
        this.hidePlay = false
        this.currentPercentage = 0
        this.hideScrollBar = false
        this.scrubbing = false
        this.currentTime = ''
        await this.$refs.videoPlayer?.pause()
        this.$refs.videoPlayer && (this.$refs.videoPlayer.currentTime = 0)
      } catch (err) {
        console.log(err)
      }
    },
    formatTime (time) {
      if (!this.$refs?.videoPlayer) return
      if (this.$refs.videoPlayer) {
        const video = this.$refs.videoPlayer
        const currentTime = Math.floor(time)
        const duration = Math.floor(video.duration)

        if (duration < 60) {
          const formattedCurrentTime =
            currentTime >= 10 ? currentTime : '0' + currentTime
          const formattedDuration = duration >= 10 ? duration : '0' + duration
          return `0:${formattedCurrentTime} / 0:${formattedDuration}`
        } else {
          const minutes = Math.floor(duration / 60)
          const seconds = duration % 60
          const formattedDuration = `${
            minutes >= 10 ? minutes : '0' + minutes
          }:${seconds >= 10 ? seconds : '0' + seconds}`

          if (currentTime >= 60) {
            const currentMinutes = Math.floor(currentTime / 60)
            const currentSeconds = currentTime % 60
            const formattedCurrentTime = `${
              currentMinutes >= 10 ? currentMinutes : '0' + currentMinutes
            }:${currentSeconds >= 10 ? currentSeconds : '0' + currentSeconds}`
            return `${formattedCurrentTime} / ${formattedDuration}`
          } else {
            const formattedCurrentTime =
              currentTime >= 10 ? currentTime : '0' + currentTime
            return `0:${formattedCurrentTime} / ${formattedDuration}`
          }
        }
      }
    },
    setCarouselIndex (index) {
      // This func is called directly on the component instance from a parent
      const carousel = this.$refs.carousel
      if (carousel) {
        carousel.setCarouselIndex(index)
      }
    }
  }
}
</script>

<style scoped>
.small-width {
  max-width: 200px;
}
</style>
