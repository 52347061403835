<template>
  <!-- Advertisement Details Drawer -->
  <BaseDrawer
    no-padding
    height-auto-only
    no-bottom-rounding
    noToolbar
    class="scrollbar-hide"
    :blockKeyboardClose="boardsDropdownOpen"
    :mobileView="mobileView"
    @close="$emit('close'), SET_AD_PLAYING('')"
  >
    <template #content>
      <!-- Content -->
      <div class="w-full flex flex-col reg-details:flex-row rounded-t-3xl bg-neutral-50 reg-details:overflow-hidden p-2 reg-details:p-0" 
      :style="{height: mobileView ? 'auto' : 'calc(100vh - 80px)', minHeight: mobileView ? 'calc(100vh - 80px)' : 'auto'}">
        <!-- TOOLBAR & AD CARD -->
        <div class="flex flex-col flex-grow" :style="{height: mobileView ? 'auto' : 'calc(100vh - 80px)'}">
          <!-- TOOLBAR -->
          <div class="relative w-full flex items-center justify-between reg-details:px-5 py-0 reg-details:py-4 mt-1">
            <!-- Previous ad button -->
            <button v-if="!mobileView" class="flex items-center gap-1.5 pl-1.5 py-1.5 pr-3 rounded-md" 
            :class="previousAd ? 'transition-colors hover:bg-neutral-25' : 'cursor-default opacity-80'" 
            :disabled="!previousAd" @click="previousAdvertisement">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="transition-colors"
              :class="previousAd ? 'text-icon-normal' : 'text-icon-disabled'">
                <path d="M11.3215 13.3327L8.57742 10.5886C8.25198 10.2632 8.25198 9.73553 8.57742 9.41009L11.3215 6.66602"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <BaseText type="label" size="sm" class="transition-colors" :class="previousAd ? 'text-text-muted' : 'text-text-disabled'">
                Previous
              </BaseText>
            </button>
            <!-- Center toolbar actions -->
            <div class="actions-shadow flex items-center gap-1 p-2 bg-white min-w-0 flex-shrink mx-auto reg-details:mx-0 w-full reg-details:w-auto" 
            :class="{'center-toolbar-actions': !mobileView}" style="border-radius: 14px">
              <!-- External ad link -->
              <div v-if="currentAd.ad_id" class="flex items-center gap-1 px-1.5 rounded-md min-w-0 flex-shrink">
                <BaseText type="body" size="sm" class="text-text-normal">
                  ID:
                </BaseText>
                <BaseText type="label" size="sm" class="text-text-muted min-w-0 flex-shrink truncate">
                  {{ currentAd.ad_id }}
                </BaseText>
                <a v-if="currentAd.live && currentAd?.publisher_platform.some((platform) => ['facebook', 'instagram'].includes(platform)) && currentAd.ad_id"
                :href="currentAd.organic ? getInstagramUrl : `https://www.facebook.com/ads/library/?id=${currentAd.ad_id}`"
                target="_blank" class="group p-1.5 rounded-md transition-colors hover:bg-neutral-25">
                  <OpenExternalIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
                </a>
              </div>
              <!-- Vertical line spacer -->
              <div v-if="currentAd.ad_id" class="w-px h-4 bg-neutral-50 mx-auto reg-details:mx-1" />
              <!-- Embed button -->
              <button class="group flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md" :disabled="!showEmbed"
              :class="showEmbed ? 'transition-colors hover:bg-neutral-25' : 'bg-neutral-25 cursor-default'"
              v-clipboard:copy="getEmbedCode" v-clipboard:success="handleEmbedClipboardCopy">
                <EmbedIcon :class="showEmbed ? 'transition-colors text-icon-normal group-hover:text-icon-muted' : 'text-icon-disabled'" />
                <BaseText type="label" size="sm" :class="showEmbed ? 'transition-colors text-text-muted group-hover:text-black' : 'text-text-disabled'">
                  Embed
                </BaseText>
              </button>
              <!-- Share button -->
              <button class="action-btn flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md" :class="{'enabled': !shareDisabled}"
              :disabled="shareDisabled" v-clipboard:copy="getShareLink" v-clipboard:success="handleClipboardCopy">
                <ShareIcon :class="shareDisabled ? 'text-text-disabled' : 'text-white'" />
                <BaseText type="label" size="sm" :class="shareDisabled ? 'text-text-disabled' : 'text-white'">
                  Share
                </BaseText>
              </button>
            </div>
            <!-- Next ad button -->
            <button v-if="!mobileView" class="flex items-center gap-1.5 pl-3 py-1.5 pr-1.5 rounded-md" 
            :class="!nextAdDisabled ? 'transition-colors hover:bg-neutral-25' : 'cursor-default opacity-60'"
            :disabled="nextAdDisabled" @click="nextAdvertisement">
              <BaseText type="label" size="sm" class="transition-colors" :class="!nextAdDisabled ? 'text-text-muted' : 'text-text-disabled'">
                Next
              </BaseText>
              <BaseLoadingSpinnerCircle v-if="loadingNewAds" class="text-icon-normal" />
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="transition-colors"
              :class="!nextAdDisabled ? 'text-icon-normal' : 'text-icon-disabled'">
                <path d="M8.33331 13.3327L11.0774 10.5886C11.4028 10.2632 11.4028 9.73553 11.0774 9.41009L8.33331 6.66602" 
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <!-- AD CARD -->
          <div class="relative w-full flex-grow min-h-0 overflow-y-scroll scrollbar-hide py-2.5 reg-details:py-6" ref="advertisement-card-area">
            <div class="max-w-sm z-10 h-max mx-auto" :class="{'centered-card-wrapper': !enableAdCardScroll}">
              <LibraryCard 
                :advertisement="currentAd || advertisement"
                :discovery="discovery"
                :shared-board="sharedBoard"
                :shared-brief="sharedBrief"
                :init-ad-copy-expanded="withAdCopyExpanded"
                in-ad-details-drawer
                all-ad-settings
                hide-boards
                no-drawer
                hide-copy-button
                relative-media
                class="w-max max-w-sm"
                ref="advertisement-card"
                @carouselIndex="handleCarouselIndexChange"
                @expandCopy="determineAdCardOverflow"
                @assetResized="determineAdCardOverflow"
              />
            </div>
          </div>
          <div v-if="currentAd?.type === 'dco' && currentAd?.cards?.length && !mobileView"
          class="absolute left-4 transform -translate-y-1/2 z-50" style="top: calc(50% - 26px)">
            <DCOPreviewSelector
              :cards="currentAd.cards"
              :carouselIndex.sync="carouselIndex"
            />
          </div>
        </div>

        <!-- RIGHT-HAND INFO CARD -->
        <div class="info-card flex flex-col mt-1 mr-1 bg-white overflow-y-scroll scrollbar-hide bg-transparent"
        :style="{height: mobileView ? 'auto' : 'calc(100vh - 80px)', width: mobileView ? '100%' : '448px'}" style="border-radius: 20px">
          <!-- Tab Switcher -->
          <div class="flex items-center gap-2 p-4 border-b border-border-normal justify-center reg-details:justify-start">
            <button class="group px-2 py-1.5 rounded-md transition-colors" :class="{'hover:bg-neutral-10': sidebarView !== 'details'}" 
            @click="sidebarView = 'details'">
              <BaseText type="label" size="sm" class="transition-colors" :class="sidebarView === 'details' ? 'text-text-muted' : 'text-text-subdued group-hover:text-text-muted'">
                Details
              </BaseText>
            </button>
            <button v-if="aiAnalysisEnabled" class="group px-2 py-1.5 flex items-center gap-1.5 rounded-md transition-colors whitespace-nowrap" 
            :class="{'hover:bg-neutral-10': sidebarView !== 'ai-analysis'}" @click="sidebarView = 'ai-analysis'">
              <BaseText type="label" size="sm" class="transition-colors" :class="sidebarView === 'ai-analysis' ? 'text-text-muted' : 'text-text-subdued group-hover:text-text-muted'">
                AI Analysis
              </BaseText>
              <div class="px-1.5 py-0.5 rounded-full" :class="`bg-primary-${getTheme}-10`">
                <BaseText type="label" size="xs" :class="`text-primary-${getTheme}-300`">New</BaseText>
              </div>
            </button>
            <button v-if="transcriptEnabled" class="group px-2 py-1.5 rounded-md transition-colors" :class="{'hover:bg-neutral-10': sidebarView !== 'transcript'}" 
            @click="sidebarView = 'transcript'">
              <BaseText type="label" size="sm" class="transition-colors" :class="sidebarView === 'transcript' ? 'text-text-muted' : 'text-text-subdued group-hover:text-text-muted'">
                Transcript
              </BaseText>
            </button>
            <button v-if="commentsEnabled" class="group flex items-center gap-1 px-2 py-1.5 rounded-md transition-colors" 
            :class="{'hover:bg-neutral-10': sidebarView !== 'comments'}" @click="sidebarView = 'comments'">
              <BaseText type="label" size="sm" class="transition-colors" :class="sidebarView === 'comments' ? 'text-text-muted' : 'text-text-subdued group-hover:text-text-muted'">
                Comments
              </BaseText>
              <BaseText v-if="comments.length" type="label" size="xs" :class="{'group-hover:bg-neutral-50': sidebarView !== 'comments'}"
              class="text-text-muted px-1.5 py-0.5 rounded-full bg-neutral-25 transition-colors">
                {{ comments.length }}
              </BaseText>
            </button>
          </div>
          <!-- DETAILS VIEW -->
          <div v-if="sidebarView === 'details'" class="flex-grow flex flex-col w-full min-h-0">
            <!-- Organization Section (Ad saving, board select, tags, rating) -->
            <div v-if="!sharedBoard && getUser.user_id" class="p-4 border-b border-border-normal"
            :class="discovery ? 'flex flex-col gap-2' : 'details-organization-grid'">
              <div v-if="!discovery && !mobileView" class="self-center">
                <BaseText type="body" size="sm" class="text-text-normal">
                  Boards
                </BaseText>
              </div>
              <div class="w-full col-span-2 reg-details:col-auto mb-2 reg-details:mb-0">
                <SaveAdDropdown 
                  :advertisement="currentAd"
                  :isSavedAd="!discovery"
                  :isEditMode="!discovery"
                  :externalAdSavedId="adCardSavedId"
                  noHoverOpener
                  coloredByDefault
                  @adSaved="$emit('adSaved', $event)"
                  @updateBoards="handleUpdateAdBoardsLocal($event)"
                  @dropdownOpened="boardsDropdownOpen = true"
                  @dropdownClosed="boardsDropdownOpen = false"
                />
              </div>
              <div v-if="discovery" class="flex items-center gap-2 p-1.5 rounded-md bg-neutral-25">
                <InfoResponseIcon class="text-icon-normal" />
                <BaseText type="body" size="sm" class="text-text-muted">
                  Save to Swipe File to share, rate, and add tags.
                </BaseText>
              </div>
              <div v-if="!discovery && currentAd.id" class="self-center py-2">
                <BaseText type="body" size="sm" class="text-text-normal">
                  Rating
                </BaseText>
              </div>
              <div v-if="!discovery && currentAd.id" class="pl-2 self-center">
                <RatingSelector
                  :adRating="currentAd.rating || 0" 
                  @updateAdRating="handleUpdateAdRating"
                />
              </div>
              <div v-if="!discovery" class="self-start py-2">
                <BaseText type="body" size="sm" class="text-text-normal">
                  Tags
                </BaseText>
              </div>
              <div v-if="!discovery" class="self-center py-0.5">
                <Tags 
                  :advertisement="currentAd" 
                  @tagDeleted="$emit('tagDeleted', $event)"
                  @update:tableViewTags="forwardTagEdits"
                />
              </div>
            </div>
            <!-- Ad details (additional ad information / metadata) -->
            <div class="scrollable-section flex-grow w-full min-h-0">
              <AdDetails 
                :advertisement="currentAd"
                :createdByUser="createdBy"
                :assetMetadata="assetMetadata"
                :discovery="discovery"
                :carouselIndex="carouselIndex"
                :hasAiAnalysis="aiAnalysisEnabled"
                :enableBrandRouting="!sharedBoard"
                :sidebarView.sync="sidebarView"
                :mobileView="mobileView"
              />
            </div>
          </div>
          <!-- AI ANALYSIS VIEW -->
          <div v-if="sidebarView === 'ai-analysis'" class="scrollable-section flex-grow w-full min-h-0">
            <AIAnalysis 
              :advertisement="currentAd"
              :mobileView="mobileView"
            />
          </div>
          <!-- TRANSCRIPT VIEW -->
          <div v-if="sidebarView === 'transcript'" class="flex-grow w-full min-h-0">
            <Transcript 
              :advertisement="currentAd"
              :carouselIndex="carouselIndex"
              :assetMetadata="assetMetadata"
              :mobileView="mobileView"
              @playProductIntro="playAdVideoAtTimestamp"
            />
          </div>
          <!-- COMMENTS VIEW -->
          <div v-if="sidebarView === 'comments'" class="flex-grow w-full min-h-0">
            <Comments v-if="currentAd"
              :can-modify-board="canModifyBoard && !discovery"
              :advertisement="currentAd"
              :comments.sync="comments"
              :commentsLoading="commentsLoading"
            />
          </div>

          <!-- Ad Action Buttons -->
          <div class="w-full flex items-center py-4 pl-4 border-t border-border-normal"
          :class="advertisementActions.length > 2 ? 'gap-3' : 'gap-5'">
            <!-- Delete Button -->
            <button v-if="advertisementActions.includes('delete')" 
            @click="handleDeleteAdvertisement" :disabled="loadingDelete"
            class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition"
            :class="loadingDelete ? 'opacity-60' : 'opacity-100 hover:bg-neutral-25'">
              <BaseLoadingSpinnerCircle v-if="loadingDelete" class="text-icon-normal" />
              <DeleteV2Icon v-else class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <BaseText type="label" size="sm" class="text-text-muted transition-colors group-hover:text-black">
                Delete
              </BaseText>
            </button>
            <!-- Report Button (TODO: Implement and add conditionals) -->
            <button v-if="advertisementActions.includes('report')" @click="showReportModal = true"
            class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25">
              <WarningIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <BaseText type="label" size="sm" class="text-text-muted transition-colors group-hover:text-black">
                Report
              </BaseText>
            </button>
            <!-- Alternate download (thumbnail / collection) -->
            <button v-if="advertisementActions.includes('download-alt')" :disabled="loadingAltDownload"
            class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition"
            :class="loadingAltDownload ? 'opacity-60 cursor-default' : 'opacity-100 hover:bg-neutral-25'"
            @click="() => {downloadAdvertisementAsset(true)}">
              <!-- Loading icon logic -->
              <BaseProgressIndicatorRing v-show="loadingAltDownload && retrievingDownloadData"
              ref="progress-ring-alt" class="text-icon-normal" :radius="10" />
              <BaseLoadingSpinnerCircle v-if="loadingAltDownload && !retrievingDownloadData" 
              class="text-icon-normal" />
              <DownloadIcon v-if="!loadingAltDownload"
              class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <!-- Alt download button text -->
              <BaseText type="label" size="sm" class="text-text-muted transition-colors "
              :class="{'group-hover:text-black': !loadingAltDownload}">
                {{ getAltDownloadLabel() }}
              </BaseText>
            </button>
            <!-- Primary asset download -->
            <button v-if="advertisementActions.includes('download')" :disabled="loadingDownload"
            class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors"
            :class="loadingDownload ? 'opacity-60 cursor-default' : 'opacity-100 hover:bg-neutral-25'"
            @click="() => {downloadAdvertisementAsset()}">
              <!-- Loading icon logic -->
              <BaseProgressIndicatorRing v-show="loadingDownload && retrievingDownloadData"
              ref="progress-ring" class="text-icon-normal" :radius="10" />
              <BaseLoadingSpinnerCircle v-if="loadingDownload && !retrievingDownloadData" 
              class="text-icon-normal" />
              <DownloadIcon v-if="!loadingDownload" 
              class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <!-- Download button text -->
              <BaseText type="label" size="sm" class="text-text-muted transition-colors capitalize"
              :class="{'group-hover:text-black': !loadingDownload}">
                {{ getDownloadLabel() }}
              </BaseText>
            </button>
          </div>
        </div>
        <!-- Hidden video element for loading metadata -->
        <video v-if="['video', 'dco'].includes(currentAd?.type) && (currentAd?.video || currentAd?.cards?.[0]?.video)" 
        ref="video" style="display: none;"
        preload="metadata" @loadedmetadata="onVideoMetadataLoaded">
          <source :src="currentAd.video" type="video/mp4" />
        </video>
      </div>
      <ReportAdModal 
        v-if="showReportModal"
        :advertisement="currentAd"
        @close="showReportModal = false"
      />
    </template>
  </BaseDrawer>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import detectAdBlock from '../../utils/adblock'
import FirebaseAPI from '@/api/firebase'
import { sendEventSocket } from '../../api/sockets'
import JSZip from 'jszip'

// Components
import Tags from './DetailsDrawerComponents/Tags'
import RatingSelector from './DetailsDrawerComponents/RatingSelector'
import SaveAdDropdown from './SaveAdDropdown/SaveAdDropdown.vue'
import AdDetails from './DetailsDrawerComponents/AdDetails.vue'
import AIAnalysis from './DetailsDrawerComponents/AIAnalysis.vue'
import Transcript from './DetailsDrawerComponents/Transcript.vue'
import Comments from './DetailsDrawerComponents/Comments'
import Screenshots from './DetailsDrawerComponents/Screenshots'
import LibraryCard from '../library/LibraryCard.vue'
import DCOPreviewSelector from './DetailsDrawerComponents/DCOPreviewSelector.vue'
import DiscoverySave from './DetailsDrawerComponents/DiscoverySave.vue'
import ReportAdModal from './DetailsDrawerComponents/ReportAdModal.vue'

// Icons
import OpenExternalIcon from '../globals/Icons/OpenExternalIcon.vue'
import EmbedIcon from '../globals/Icons/EmbedIcon.vue'
import ShareIcon from '../globals/Icons/ShareIcon.vue'
import InfoResponseIcon from '../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'
import DeleteV2Icon from '../globals/Icons/DeleteV2Icon.vue'
import DownloadIcon from '../globals/Icons/DownloadIcon.vue'
import WarningIcon from '../globals/Icons/WarningIcon.vue'

export default {
  name: 'AdvertisementDetailsDrawer',
  components: {
    Tags,
    RatingSelector,
    SaveAdDropdown,
    AdDetails,
    AIAnalysis,
    Transcript,
    Comments,
    Screenshots,
    DiscoverySave,
    ReportAdModal,
    LibraryCard,
    DCOPreviewSelector,
    OpenExternalIcon,
    EmbedIcon,
    ShareIcon,
    InfoResponseIcon,
    DeleteV2Icon,
    DownloadIcon,
    WarningIcon
  },
  props: {
    allAds: {
      type: Array,
      default: () => []
    },
    advertisement: {
      type: Object,
      default: () => {}
    },
    sharedBoard: {
      type: Boolean,
      default: () => false
    },
    sharedBrief: {
      type: Boolean,
      default: () => false
    },
    discovery: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    adCardSavedId: {
      type: String,
      default: ''
    },
    allAdsLoaded: {
      type: Boolean,
      default: () => false
    },
    withAdCopyExpanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      // Data States
      currentAd: {},
      previousAd: {},
      nextAd: {},
      selectedBoards: [],
      selectedRating: [],
      selectedTags: [],
      createdBy: {},
      niches: [],
      betaUser: false,
      assetMetadata: {},
      comments: [],
      carouselIndex: 0,

      // UI States
      sidebarView: 'details',
      loadingNewAds: false,
      loadingTranscription: false,
      adBlockedDetected: false,
      loadingDelete: false,
      commentsLoading: false,
      stars: [{ icon: '⭐⭐⭐⭐⭐', value: 5 }, { icon: '⭐⭐⭐⭐', value: 4 }, { icon: '⭐⭐⭐', value: 3 }, { icon: '⭐⭐', value: 2 }, { icon: '⭐', value: 1 }],
      boardsDropdownOpen: false,
      enableAdCardScroll: false,
      showReportModal: false,
      noCommentsImgSrc: require('../../assets/images/break-the-silence.png'),
      mobileView: window.innerWidth < 1200,

      // Download States
      loadingDownload: false,
      loadingAltDownload: false,
      retrievingDownloadData: false,
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getUserName']),
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('TagsModule', ['getTags']),
    ...mapGetters('MiscModule', ['getTheme']),
    showEmbed () {
      return !(
        this.currentAd.publisher_platform?.includes('landing page') 
        || ['carousel', 'dco', 'dpa'].includes(this.currentAd?.type)
      )
    },
    getInstagramUrl () {
      return `https://www.instagram.com/p/${this.currentAd?.instagramAttributes?.shortCode}`
    },
    advertisementActions () {
      // The list of action buttons to render at the bottom of the info card
      const actions = []
      if (!this.discovery && !this.sharedBoard) actions.push('delete')
      if (this.discovery) actions.push('report')
      if (
        (this.currentAd?.type === 'video' && this.currentAd.thumbnail)
        || (['carousel', 'dco'].includes(this.currentAd?.type) && this.currentAd?.cards?.length)
      ) {
        actions.push('download-alt')
      }
      if (
        (this.currentAd?.type === 'video' && this.currentAd.video)
        || (this.currentAd?.type === 'image' && this.currentAd.image)
        || (this.currentAd?.type === 'carousel' && this.currentAd?.cards?.length)
        || (this.currentAd?.type === 'dco' && this.currentAd?.cards?.length)
      ) {
        actions.push('download')
      }
      return actions
    },
    canModifyBoard () {
      return !this.sharedBoard && !this.sharedBrief
    },
    getEmbedCode () {
      const randomlyGeneratedId = Math.random().toString(36).substring(7)
      return `<iframe id="${randomlyGeneratedId}" src="${
        process.env.VUE_APP_API_URL
      }/embed/${
        this.currentAd.id
      }" allowtransparency="true" scrolling="no" frameborder="0" width="100%"></iframe><script>function resizeIframe(e){var t=document.getElementById("${randomlyGeneratedId}");e.data&&e.data.height&&(t.style.height=e.data.height+"px")}window.addEventListener("message",resizeIframe,!1);${'</scr'}ipt>`
    },
    getShareLink () {
      let path = this.$route.path
      let pathName
      path = path.split('/')
      if (path[1] === 'library') {
        pathName = 'Swipe FIles'
      } else if (path[1] === 'boards') {
        pathName = 'Board'
      } else if (path[1] === 'discovery') {
        pathName = 'Discovery'
      }
      return (
        process.env.VUE_APP_BASE_URL +
        '/share/ads/' +
        encodeURIComponent(this.currentAd.id) +
        '?user=' +
        encodeURIComponent(this.getUserName)
      )
    },
    shareDisabled () {
      return this.discovery || !this.canModifyBoard
    },
    onLastAd () {
      return this.allAdsLoaded && this.allAds.indexOf(this.currentAd) === this.allAds.length - 1
    },
    nextAdDisabled () {
      return this.onLastAd || ((this.loadingNewAds || this.loading) && !this.nextAd)
    },
    aiAnalysisEnabled () {
      return this.currentAd?.name !== 'Manual Upload'
        && ['image', 'video', 'carousel', 'dco'].includes(this.currentAd?.type)
    },
    transcriptEnabled () {
      return this.currentAd?.type === 'video'
        || (this.currentAd?.type === 'dco' && this.currentAd?.cards?.length && this.currentAd.cards[this.carouselIndex].video)
    },
    commentsEnabled () {
      return !this.discovery && !this.mobileView
    }
  },
  watch: {
    getBoards (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.refreshBoards()
      }
    },
    allAds () {
      this.loadingNewAds = false
    },
    allAdsLoaded () {
      this.loadingNewAds = false
    },
    carouselIndex () {
      const card = this.$refs['advertisement-card']
      if (card) {
        card.setCarouselIndex(this.carouselIndex)
      }
      if (this.sidebarView === 'transcript' && !this.transcriptEnabled) {
        this.sidebarView = 'details'
      }
    },
    currentAd: {
      async handler (newValue, oldValue) {
        // When navigating through ads, we may need to update the sidebar view
        if (
          (this.sidebarView === 'transcript' && !this.transcriptEnabled)
          || (this.sidebarView === 'comments' && !this.commentsEnabled)
          || (this.sidebarView === 'ai-analysis' && !this.aiAnalysisEnabled)
        ) {
          this.sidebarView = 'details'
        }

        this.carouselIndex = 0

        // Extract metadata from ad asset
        if (this.currentAd?.type === 'image' && this.currentAd.image) {
          const image = new Image()
          image.src = this.currentAd.image
          if (image.complete) { // In case the image was previously cached
            this.assetMetadata = { width: image.width, height: image.height }
          } else { // Otherwise, wait for the image to load
            image.onLoad = () => {
              this.assetMetadata = { width: image.width, height: image.height }
            }
          }
        } else if (
          (this.currentAd?.type === 'video' && this.currentAd.video)
          || (this.currentAd?.type === 'dco' && this.currentAd?.cards?.length && this.currentAd.cards[0].video)
        ) {
          this.$nextTick(() => {
            const video = this.$refs.video
            video.pause()
            video.src = ''
            video.src = this.currentAd.video || this.currentAd.cards[0].video
            video.load()
            // The metadata computations are perfomed when the <video> element emits the 'loadedmetadata' event
          })
        }

        if (newValue.id && newValue.id !== oldValue.id) {
          sendEventSocket('adViewed', { adId: this.currentAd.id, brandId: this.currentAd.brandId })
        }

        // Load the comments for the current ad
        this.commentsLoading = true
        try {
          const comments = await FirebaseAPI.Comments.getCommentsByAdId(this.currentAd.id)
          this.comments = comments.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
          this.commentsLoading = false
        } catch (error) {
          console.error("Failed to load ad comments:", error)
        }
      },
      immediate: true
    }
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize)

    this.SET_AD_PLAYING('')
    this.currentAd = this.advertisement
    this.previousAd = this.allAds[(this.allAds.indexOf(this.advertisement) - 1)]
    this.nextAd = this.allAds[(this.allAds.indexOf(this.advertisement) + 1)]

    // Check for transcription
    try {
      const updatedAd = await FirebaseAPI.Advertisements.get(this.currentAd.id)

      if (updatedAd.timestampedTranscription) {
        this.currentAd.timestampedTranscription = updatedAd.timestampedTranscription
        this.currentAd.transcriptionStatus = updatedAd.transcriptionStatus
      }
    } catch (error) {
      console.log(error)
    }
    this.determineAdCardOverflow()

    const adTags = this.currentAd.tags || []
    this.selectedTags = await FirebaseAPI.Tags.getTagsArray(adTags)

    // Check beta user
    const betaIds = await FirebaseAPI.Beta.getBetaUserIds()
    const mappedIds = betaIds.map(id => id.id)

    if (mappedIds.includes(this.getUser.user_id)) {
      this.betaUser = true
    }

    if (this.currentAd?.rating) {
      this.selectedRating = [this.stars.find(star => this.currentAd?.rating === star.value)]
    } else {
      this.selectedRating = []
    }
    setTimeout(() => {
      this.refreshBoards()
    }, 100)

    this.detectAdBlock().then((detected) => {
      if (detected) {
        this.adBlockedDetected = true
      }
    })

    // Cache the empty comments image
    const image = new Image()
    image.src = this.noCommentsImgSrc

    await this.getCreatedByUser()
    await this.getNiches()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    ...mapMutations('AdvertisementsModule', ['SET_AD_PLAYING']),
    detectAdBlock,

    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    handleCarouselIndexChange (index) {
      this.carouselIndex = index
    },
    async handleUpdateAdRating (rating) {
      try {
        await FirebaseAPI.Advertisements.update(this.currentAd.id, { rating: rating })
        const copyAd = { ...this.currentAd, rating: rating }
        this.$emit('updateAds', [copyAd])
        this.currentAd = copyAd

        this.$showAlert({
          message: 'Ad rating updated',
          type: 'success'
        })
      } catch (error) {
        console.error("Couldn't update ad rating", error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
    },
    handleUpdateAdTags: _.debounce(async function () {
      try {
        const payload = {
          tags: this.selectedTags.map((e) => e.id)
        }

        await FirebaseAPI.Advertisements.update(this.currentAd.id, payload)

        // Update Parent Ad
        const copyAd = { ...this.currentAd, tags: payload.tags }
        this.$emit('updateAds', [copyAd])
        this.currentAd = copyAd

        this.$showAlert({
          message: 'Ad tags updated',
          type: 'success'
        })
        this.$emit('update')
      } catch (e) {
        this.$showAlert({
          message: e,
          type: 'error'
        })
      }
    }, 1000),
    handleUpdateAdBoardsLocal (boardIds) {
      const copyAd = { ...this.currentAd, board_ids: boardIds }
      this.$emit('updateAds', [copyAd])
      this.currentAd = copyAd
    },
    async nextAdvertisement () {
      this.SET_AD_PLAYING('')
      if (this.allAds.indexOf(this.currentAd) === this.allAds.length - 1) {
        console.log('get more ads')
        this.loadingNewAds = true
        this.$emit('getMoreAds')
        return
      }
      this.loadingNewAds = false
      if (this.nextAd) {
        this.currentAd = this.nextAd
      } else {
        this.currentAd = this.allAds[(this.allAds.indexOf(this.currentAd) + 1)]
      }
      this.previousAd = this.allAds[(this.allAds.indexOf(this.currentAd) - 1)]
      this.nextAd = this.allAds[(this.allAds.indexOf(this.currentAd) + 1)]
      this.refreshBoards()
      if (this.currentAd?.rating) {
        this.selectedRating = [this.stars.find(star => this.currentAd?.rating === star.value)]
      } else {
        this.selectedRating = []
      }
      this.determineAdCardOverflow()
      const adTags = this.currentAd.tags || []
      this.selectedTags = await FirebaseAPI.Tags.getTagsArray(adTags)
    },
    async previousAdvertisement () {
      this.SET_AD_PLAYING('')
      if (this.allAds.indexOf(this.currentAd) === 0) {
        return
      }
      this.currentAd = this.previousAd
      this.previousAd = this.allAds[(this.allAds.indexOf(this.currentAd) - 1)]
      this.nextAd = this.allAds[(this.allAds.indexOf(this.currentAd) + 1)]
      this.refreshBoards()
      if (this.currentAd?.rating) {
        this.selectedRating = [this.stars.find(star => this.currentAd?.rating === star.value)]
      } else {
        this.selectedRating = []
      }
      this.determineAdCardOverflow()
      const adTags = this.currentAd.tags || []
      this.selectedTags = await FirebaseAPI.Tags.getTagsArray(adTags)
    },
    async getCreatedByUser () {
      if (this.currentAd?.created_by) {
        const { avatar, name } = await FirebaseAPI.Users.getById(this.currentAd.created_by)
        this.createdBy = { avatar, name }
      }
    },
    async getNiches () {
      // Setup
      try {
        const db = firebase.firestore()

        const snapshot = await db.collection('niches').get()

        const newDocs = snapshot.docs?.map((doc) => {
          const docData = {
            ...(doc.data() || [])
          }

          return { name: docData.niche, icon: docData.emoji }
        })

        const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
        const otherIndex = sortedDocs.findIndex((d) => d.name === 'Other')
        const otherNiche = sortedDocs.splice(otherIndex, 1)

        sortedDocs.push(otherNiche[0])

        this.niches = sortedDocs
      } catch (e) {
        console.log(e)
      }
    },

    // ================== DOWNLOAD METHODS ==================

    async downloadAdvertisementAsset (alternate = false) {
      // Reset progress states and flag loading
      this.targetDownloadProgress = 0
      this.renderedDownloadProgress = 0
      alternate ? this.loadingAltDownload = true : this.loadingDownload = true

      // Get the progress animation function
      const refStr = alternate ? 'progress-ring-alt' : 'progress-ring'
      const animateProgress = this.$refs[refStr].updateProgress

      // Configure the download based on the advertisement and type
      let url = null
      let filename = null
      try {
        if (this.currentAd?.type === 'image') {
          filename = `image-${this.currentAd.id}`
          url = this.currentAd.image
        } else if (this.currentAd?.type === 'video') {
          filename = `${alternate ? 'thumbnail' : 'video'}-${this.currentAd.id}`
          url = alternate ? this.currentAd.thumbnail : this.currentAd.video
        } else if (this.currentAd?.type === 'carousel') {
          if (alternate) {
            filename = `carousel-${this.currentAd.id}`
            await this.executeCollectionDownload(this.currentAd.cards, filename, animateProgress)
            return
          }
          filename = `carousel-${this.carouselIndex}-${this.currentAd.id}`
          url = this.currentAd.cards[this.carouselIndex].image
            || this.currentAd.cards[this.carouselIndex].video
        } else if (this.currentAd?.type === 'dco') {
          if (alternate) {
            filename = `dco-${this.currentAd.id}`
            await this.executeCollectionDownload(this.currentAd.cards, filename, animateProgress)
            return
          }
          filename = `dco-${this.carouselIndex}-${this.currentAd.id}`
          url = this.currentAd.cards[this.carouselIndex].image
            || this.currentAd.cards[this.carouselIndex].video
        }
        await this.executeDownload(url, filename, animateProgress)

      } catch (error) {
        this.executeBackupDownload(url, error)
      } finally {
        alternate ? this.loadingAltDownload = false : this.loadingDownload = false
      }
    },
    async executeDownload (url, filename, animateProgress) {
      // Fetch the asset
      const response = await fetch(url, { mode: 'cors', })

      // Get the content length to track download progress
      const contentLength = response.headers.get('Content-Length')
      if (!response.body || !contentLength) {
        throw new Error('Readable steams or content length not supported')
      }

      this.retrievingDownloadData = true

      // Create a readable stream
      const totalBytes = parseInt(contentLength, 10)
      let loadedBytes = 0
      const reader = response.body.getReader()
      const stream = new ReadableStream({
        start: async (controller) => {
          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            loadedBytes += value.byteLength
            animateProgress(loadedBytes / totalBytes)

            controller.enqueue(value)
          }
          controller.close()
        }
      })

      const blob = await new Response(stream).blob()
      this.retrievingDownloadData = false

      // Create a downloadable URL for the fetched asset
      const downloadUrl = URL.createObjectURL(blob)
      const fileExtension = url.split('.').pop()
      const fullFilename = `${filename}.${fileExtension}`
      this.triggerDownload(downloadUrl, fullFilename)
    },
    async executeCollectionDownload (assets, filename, animateProgress) {
      // Assets are expected to be an object array with image or video keys

      // Prepare the jszip instance and progress variables
      const zip = new JSZip()
      let totalBytes = 0
      let loadedBytes = 0

      // First we fetch the content length for each asset to track download progress
      const assetSizes = await Promise.all(
        assets.map(async (asset) => {
          const url = asset.image || asset.video
          const response = await fetch(url, { method: 'HEAD', mode: 'cors' })
          return parseInt(response.headers.get('Content-Length'), 10)
        })
      )
      totalBytes = assetSizes.reduce((acc, size) => acc + size, 0)

      this.retrievingDownloadData = true

      // Fetch and add each asset to the zip file
      await Promise.all(
        assets.map(async (asset, index) => {
          const url = asset.image || asset.video
          const response = await fetch(url, { mode: 'cors' })
          if (!response.body) {
            throw new Error('Readable steams not supported')
          }

          const reader = response.body.getReader()
          const fileExtension = url.split('.').pop()
          const fullFilename = `${index}-${filename}.${fileExtension}`
          const fileData = []

          // Create a readable stream
          const stream = new ReadableStream({
            async start(controller) {
              while (true) {
                const { done, value } = await reader.read()
                if (done) break

                fileData.push(value)
                loadedBytes += value.byteLength
                animateProgress(loadedBytes / totalBytes)
                controller.enqueue(value)
              }
              controller.close()
            }
          })

          const blob = await new Response(stream).blob()
          const arrayBuffer = await blob.arrayBuffer()
          zip.file(fullFilename, arrayBuffer)
        })
      )
      this.retrievingDownloadData = false

      // Generate the zip file and download it
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      const zipUrl = URL.createObjectURL(zipBlob)
      this.triggerDownload(zipUrl, `${filename}.zip`)
    },
    async executeBackupDownload (url, error) {
      if (!url) {
        console.error('Download failed:', error)
        this.$showAlert({
          message: 'Failed to download asset',
          type: 'error'
        })
        return
      }

      // Open the download url in a new tab
      window.open(url, '_blank')
    },
    triggerDownload (url, downloadFilename) {
      // Create a hidden anchor element to trigger the download
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = downloadFilename
      anchor.addEventListener('click', (event) => { event.stopPropagation() })
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)

      // Revoke the URL to free up memory
      URL.revokeObjectURL(url)
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    showIntercom () {
      window.Intercom('show')
    },
    determineAdCardOverflow () {
      if (this.mobileView) {
        this.enableAdCardScroll = true
        return
      }
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          const cardArea = this.$refs["advertisement-card-area"]
          const card = this.$refs["advertisement-card"].$el
          if (cardArea && card) {
            // Subtract 48px for padding and 24px for vert center offset
            const scrollThreshold = cardArea.getBoundingClientRect().height - 48 - 24 
            const cardHeight = card.getBoundingClientRect().height
            this.enableAdCardScroll = cardHeight > scrollThreshold
          }
        })
      })
    },
    refreshBoards () {
      this.selectedBoards = this.getBoards.filter((board) =>
        this.currentAd.board_ids?.includes(board.id)
      )
    },
    getDownloadLabel () {
      if (!this.currentAd.type) return 'Download'
      switch (this.currentAd.type) {
        case 'carousel': return 'Current Card'
        case 'dco': return 'Current Variant'
        default: return this.currentAd.type
      }
    },
    getAltDownloadLabel () {
      if (!this.currentAd.type) return 'Alt'
      switch (this.currentAd.type) {
        case 'video': return 'Thumbnail'
        default: return 'Collection'
      }
    },

    // ================================================================================
    // ================================ EVENT HANDLERS ================================
    // ================================================================================

    handleResize: _.debounce(function () {
      this.mobileView = window.innerWidth < 1200
    }, 100),
    // Handle Ad Share Link Copy
    handleClipboardCopy () {
      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    handleEmbedClipboardCopy () {
      this.$showAlert({
        message: 'Copied embed code to clipboard!',
        type: 'success'
      })
    },
    playAdVideoAtTimestamp (time) {
      const adCard = this.$refs['advertisement-card']
      if (!adCard) return
      adCard.playVideoAtTimestamp(time)
    },
    onVideoMetadataLoaded () {
      const video = this.$refs.video
      const { duration, videoWidth, videoHeight } = video
      this.assetMetadata = { duration, width: videoWidth, height: videoHeight }
      video.pause()
      video.src = ''
      video.load() // Reinitializing load without source will stop any ongoing download
    },
    // Handle Delete Advertisement
    async handleDeleteAdvertisement () {
      const db = firebase.firestore()
      this.loadingDelete = true

      try {
        await db.collection('ads').doc(this.currentAd.id).delete()

        // TODO: Delete comments too

        // See if brand should be deleted
        if (this.currentAd.brandId) {
          await FirebaseAPI.Search.shouldBrandBeDeleted(this.currentAd)
        }

        // If manual upload, delete the saved asset
        if (this.currentAd.fileData) {
          // Delete the firebase asset
          const storageRef = firebase
            .app()
            .storage('gs://foreplay-manual-upload')
            .ref(this.currentAd.fileData.filePath)
          await storageRef.delete()

          // Update the users data limit
          const storageUsed =
            this.getUser.storageUsed -
            Math.ceil(this.currentAd.fileData.size / 1000)

          const user = firebase.auth().currentUser
          await FirebaseAPI.Users.update(user.uid, { storageUsed })

          // Set the local user
          this.SET_USER({ ...this.getUser, storageUsed })
        }

        this.$emit('removeAds', [this.currentAd])
        this.$showAlert({
          message: 'Ad deleted successfully!',
          type: 'success'
        })

        this.$emit('close')
        this.$emit('save')
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingDelete = false
      }
    },
    forwardTagEdits (tagId, updatedTag) {
      this.$emit('update:tableViewTags', tagId, updatedTag)
    }
  }
}
</script>

<style scoped>
@property --share-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --share-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}

.info-card {
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.06), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.06);
}
.actions-shadow {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.09), 0px 0px 0px 1px rgba(0, 56, 108, 0.04);
}
.center-toolbar-actions {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center-toolbar-actions .action-btn:disabled {
  cursor: default;
  background-color: #F6F8FA;
}
.action-btn.enabled {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--share-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--share-btn-opacity-2)) 100%), #006BFF;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: --share-btn-opacity-1 150ms ease-in-out, --share-btn-opacity-2 150ms ease-in-out;
  transform: scale(0.95);
  transform-origin: center;
}
.action-btn.enabled:hover {
  --share-btn-opacity-1: 0.24;
  --share-btn-opacity-2: 0;
}
.action-btn.enabled:active {
  --share-btn-opacity-1: 0;
  --share-btn-opacity-2: 0.24;
}
.centered-card-wrapper {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.details-organization-grid {
  display: grid;
  grid-template-columns: 136px auto;
  max-width: 100%;
}

.gray-background{
  background-image: url('../../assets/images/gray-striped-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-shadow {
  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  border-radius: 999px;
}

.scrollable-section {
  overflow-y: auto;
}
.scrollable-section::-webkit-scrollbar {
  width: 3px;
}
.scrollable-section::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 10000px;
}
</style>
