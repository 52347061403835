<template>
  <div class="w-full flex flex-col">
    <!-- AD DETAILS SECTION -->
    <div class="info-grid w-full px-4 py-3 gap-y-2.5 border-b border-border-normal">
      <!-- BRAND -->
      <div v-if="advertisement?.name !== 'Manual Upload'" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Brand
        </BaseText>
      </div>
      <div v-if="advertisement?.name !== 'Manual Upload'" class="relative">
        <button class="self-center flex items-center gap-2 pr-2 min-w-0 max-w-full" style="border-radius: 4px"
        :class="brandActionsEnabled ? `transition-colors ${brandOptionsOpen ? '' : 'hover:'}bg-neutral-25` : 'cursor-default'" 
        :disabled="!brandActionsEnabled"
        @click="brandOptionsOpen = !brandOptionsOpen">
          <img :src="advertisement.avatar" class="w-6 h-6" style="border-radius: 4px" />
          <BaseText type="label" size="sm" class="text-text-muted truncate">
            {{ advertisement.name }}
          </BaseText>
        </button>
        <!-- Brand Actions Popup -->
        <transition>
          <div v-if="brandOptionsOpen && brandActionsEnabled" class="brand-options-popup"
          v-on-clickaway="() => { brandOptionsOpen = false }">
            <BrandActionsTooltip 
              :brandId="advertisement?.brandId || advertisement?.brand_Id"
              :brandAdLibraryId="brandAdLibraryId"
              :brandDiscoveryAdCount="brandDiscoveryAdCount"
              :canTrackAsSpyder="canTrackBrandAsSpyder"
            />
          </div>
        </transition>
      </div>
      <!-- SAVED BY -->
      <div v-if="createdByUser?.name" class="self-start py-0.5">
        <BaseText type="body" size="sm" class="text-text-normal">
          Saved By
        </BaseText>
      </div>
      <div v-if="createdByUser?.name" class="self-center flex items-center gap-x-2 gap-y-0.5">
        <img v-if="createdByUser.avatar" :src="createdByUser.avatar" class="rounded-full w-6 h-6" alt="" />
        <div v-else class="p-0.5">
          <DefaultAvatarIcon class="text-icon-normal" />
        </div>
        <div class="flex items-center gap-x-2 flex-wrap">
          <BaseText type="label" size="sm" class="text-text-muted truncate">
            {{ createdByUser.name }}
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal">
            {{ formatTime(advertisement.createdAt) }}
          </BaseText>
        </div>
      </div>
      <!-- LIVE STATUS -->
      <div v-if="hasLiveStatus" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Status
        </BaseText>
      </div>
      <div v-if="hasLiveStatus" class="self-center flex items-center gap-2">
        <div style="padding: 9px">
          <div class="h-1.5 w-1.5 rounded-full" :style="{backgroundColor: advertisement?.live ? '#00C7A9' : '#DFE1E7'}" />
        </div>
        <div v-if="advertisement?.live" class="flex items-center flex-wrap reg-details:flex-nowrap gap-x-2 gap-y-0.5">
          <BaseText type="label" size="sm" class="text-text-muted">
            Still Running
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal">
            from {{ formatTime(advertisement.startedRunning) }}
          </BaseText>
        </div>
        <div v-else class="flex items-center gap-x-2 gap-y-0.5 flex-wrap">
          <BaseText type="label" size="sm" class="text-text-muted">
            {{ `${formatTime(advertisement.startedRunning)} ${mobileView ? 'to' : '-'}` }}
          </BaseText>
          <BaseText type="label" size="sm" class="text-text-muted">
            {{ formatEndDate(advertisement?.lastChecked || advertisement?.last_checked) }}
          </BaseText>
        </div>
      </div>
      <!-- TIME RUNNING -->
      <div v-if="hasLiveStatus" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Time Running
        </BaseText>
      </div>
      <div v-if="hasLiveStatus" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <TimeRunningIcon class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted">
          {{
            `${
              formatRunningDuration(
                advertisement?.startedRunning,
                advertisement?.lastChecked || advertisement?.last_checked
              )}
            ${
              formatRunningDuration(
                advertisement?.startedRunning,
                advertisement?.lastChecked || advertisement?.last_checked
              ) > 1
                ? "Days"
                : "Day"
            }`
          }}
        </BaseText>
      </div>
      <!-- CTA -->
      <div v-if="ctaText" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          CTA
        </BaseText>
      </div>
      <div v-if="ctaText" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <CtaIcon class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted">
          {{ ctaText }}
        </BaseText>
      </div>
      <!-- FORMAT -->
      <div v-if="advertisement?.type" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Format
        </BaseText>
      </div>
      <div v-if="advertisement?.type" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <component :is="getFormatIcon(advertisement.type)" class="w-5 h-5 text-icon-normal" stroke="#5E6678" 
          :fill="advertisement.type === 'dpa' ? '#5E6678' : 'none'" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted" :class="advertisement.type === 'dpa' ? 'uppercase' : 'capitalize'">
          {{ advertisement.type === 'dco' ? 'Dynamic Content Optimization' : advertisement.type }}
        </BaseText>
      </div>
      <!-- NICHE -->
      <div v-if="advertisement?.niches?.length" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Niche
        </BaseText>
      </div>
      <div v-if="advertisement?.niches?.length" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <component :is="getNicheIcon(advertisement.niches[0])" class="w-5 h-5" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted">
          {{ advertisement.niches[0] }}
        </BaseText>
      </div>
      <!-- PLATFORMS -->
      <div v-if="advertisement?.publisher_platform?.length">
        <BaseText type="body" size="sm" class="text-text-normal">
          Platforms
        </BaseText>
      </div>
      <div v-if="advertisement?.publisher_platform?.length" class="flex flex-wrap gap-x-2.5 gap-y-1.5">
        <div v-for="platform in advertisement.publisher_platform.filter(platform => platform !== 'audience_network')" 
        :key="platform" class="flex flex-nowrap items-center gap-2">
          <div class="py-0.5 px-1">
            <component :is="getPlatformIcon(platform)" class="w-4 h-4" />
          </div>
          <BaseText type="label" size="sm" class="text-text-muted capitalize">
            {{ platform }}
          </BaseText>
        </div>
      </div>
      <!-- LANDING PAGE -->
      <div v-if="advertisement.link_url" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Landing Page
        </BaseText>
      </div>
      <div v-if="advertisement.link_url" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <LinkRoundedIcon class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted truncate pr-1">
          <a :href="advertisement.link_url" target="_blank" class="transition-colors hover:text-black 
          pointer-events-none reg-details:pointer-events-auto">
            {{ advertisement.link_url }}
          </a>
        </BaseText>
      </div>
      <!-- ASPECT RATIO -->
      <div v-if="canComputeAspectRatio" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Aspect Ratio
        </BaseText>
      </div>
      <div v-if="canComputeAspectRatio" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <AspectRatioVerticalIcon v-if="aspectRatio.height > aspectRatio.width" class="text-icon-normal" />
          <AspectRatioHorizontalIcon v-else-if="aspectRatio.width > aspectRatio.height" class="text-icon-normal" />
          <AspectRatioIcon v-else class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted">
          {{ aspectRatio.name ?? '' }}
        </BaseText>
      </div>
    </div>
    <!-- AI INSIGHTS SECTION -->
    <div class="info-grid w-full px-4 py-3 gap-y-2.5">
      <!-- PRODUCT INTRO -->
      <div v-if="hasProductIntro" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Product Intro
        </BaseText>
      </div>
      <div v-if="hasProductIntro" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <ProductIntroIcon class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted">
          {{ productIntro }}
        </BaseText>
      </div>
      <!-- CONTENT STYLE -->
      <div v-if="contentStyle" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Content Style
        </BaseText>
      </div>
      <div v-if="contentStyle" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <OtherNicheIcon stroke="#5E6678" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted capitalize">
          {{ contentStyle }}
        </BaseText>
      </div>
      <!-- PRODUCT CATEGORY -->
      <div v-if="advertisement.productCategory" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Product Category
        </BaseText>
      </div>
      <div v-if="advertisement.productCategory" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <ProductCategoryIcon class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted capitalize">
          {{ advertisement.productCategory }}
        </BaseText>
      </div>
      <!-- MARKET TARGET -->
      <div v-if="hasMarketTarget" class="self-center">
        <BaseText type="body" size="sm" class="text-text-normal">
          Target Market
        </BaseText>
      </div>
      <div v-if="hasMarketTarget" class="self-center flex items-center gap-2">
        <div class="p-0.5">
          <B2CIcon v-if="advertisement.marketTarget === 'b2c'" class="text-icon-normal" />
          <B2BIcon v-else class="text-icon-normal" />
        </div>
        <BaseText type="label" size="sm" class="text-text-muted uppercase">
          {{ advertisement.marketTarget }}
        </BaseText>
      </div>
      <!-- View More Button -->
      <button v-if="hasAiAnalysis" class="relative right-1.5 col-span-2 flex items-center gap-1.5 py-1 pl-1.5 pr-1 mt-1 
      rounded-md transition-colors hover:bg-neutral-10 w-min flex-nowrap" @click="viewAIAnalysis">
        <BaseText type="label" size="sm" class="text-text-muted whitespace-nowrap">
          View full AI analysis
        </BaseText>
        <div class="px-1.5 py-0.5 rounded-full" :class="`bg-primary-${getTheme}-10`">
          <BaseText type="label" size="xs" :class="`text-primary-${getTheme}-300`">New</BaseText>
        </div>
      </button>
      <!-- DCO Extra info tip -->
      <div v-if="advertisement?.type === 'dco'" class="w-full col-span-2 flex items-center gap-1.5 p-1.5 rounded-md bg-neutral-25">
        <InfoResponseIcon class="text-icon-normal" />
        <BaseText type="body" size="sm" class="text-text-muted">
          Analysis for DCO Ads only supports the first ad variant.
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { mixin as clickaway } from 'vue-clickaway2'
import FirebaseAPI from '@/api/firebase'

import BrandActionsTooltip from '../BrandActionsTooltip.vue'

// Icons
import DefaultAvatarIcon from '../../globals/Icons/DefaultAvatarIcon.vue'
import TimeRunningIcon from '../../globals/Icons/TimeRunningIcon.vue'
import CtaIcon from '../../globals/Icons/CtaIcon.vue'
import LinkRoundedIcon from '../../globals/Icons/LinkRoundedIcon.vue'
import OtherNicheIcon from '../../globals/Icons/FilterIcons/Niches/OtherNicheIcon.vue'
import VideoFormatIcon from '../../globals/Icons/FilterIcons/VideoFormatIcon.vue'
import AspectRatioIcon from '../../globals/Icons/AspectRatioIcon.vue'
import AspectRatioVerticalIcon from '../../globals/Icons/AspectRatioVerticalIcon.vue'
import AspectRatioHorizontalIcon from '../../globals/Icons/AspectRatioHorizontalIcon.vue'
import ProductIntroIcon from '../../globals/Icons/ProductIntroIcon.vue'
import ProductCategoryIcon from '../../globals/Icons/ProductCategoryIcon.vue'
import B2BIcon from '../../globals/Icons/B2BIcon.vue'
import B2CIcon from '../../globals/Icons/B2CIcon.vue'
import InfoResponseIcon from '../../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'
import DCOIcon from '../../globals/Icons/DCOIcon.vue'
import * as Icons from '../../globals/Icons/FilterIcons'

export default {
  name: 'AdDetails',
  mixins: [clickaway],
  components: {
    BrandActionsTooltip,
    DefaultAvatarIcon,
    TimeRunningIcon,
    CtaIcon,
    LinkRoundedIcon,
    OtherNicheIcon,
    VideoFormatIcon,
    AspectRatioIcon,
    AspectRatioVerticalIcon,
    AspectRatioHorizontalIcon,
    ProductIntroIcon,
    ProductCategoryIcon,
    B2BIcon,
    B2CIcon,
    InfoResponseIcon,
  },
  props: {
    advertisement: {
      type: Object,
      required: true
    },
    createdByUser: {
      type: Object,
      default: () => {}
    },
    assetMetadata: {
      type: Object,
      default: () => {}
    },
    discovery: {
      type: Boolean,
      required: true
    },
    carouselIndex: {
      type: Number,
      default: 0
    },
    sidebarView: {
      type: String,
      required: true
    },
    hasAiAnalysis: {
      type: Boolean,
      default: false
    },
    enableBrandRouting: {
      type: Boolean,
      default: false
    },
    mobileView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      brandOptionsOpen: false,
      brandDiscoveryAdCount: 0,
      brandAdLibraryId: null
    }
  },
  watch: {
    advertisement: {
      async handler (newAd) {
        if (this.brandOptionsOpen) this.brandOptionsOpen = false

        // Load the brand information for the current ad
        console.log('Loading brand information for ad:', newAd)
        try {
          const brand = await FirebaseAPI.Brands.get(newAd.brandId || newAd.brand_Id)
          this.brandDiscoveryAdCount = brand.adsSaved || 0
          this.brandAdLibraryId = brand?.adLibraryId
        } catch (error) {
          console.error("Failed to load brand information for ad:", error)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    contentStyle () {
      if (!this.advertisement.contentFilter) return null
      // Find the contentFilter key with the highest value (that isn't other)
      return Object.entries(this.advertisement.contentFilter)
        .filter(([key]) => key !== 'other')
        .sort((a, b) => b[1] - a[1])[0][0]
    },
    hasLiveStatus () {
      return this.advertisement?.startedRunning 
        && this.advertisement.publisher_platform?.some((platform) => ['facebook','messenger','instagram']?.includes(platform)) 
        && this.advertisement?.name !== 'Manual Upload' 
        && !this.advertisement?.organic
    },
    hasMarketTarget () {
      if (!this.advertisement.marketTarget) return false
      return ['b2b', 'b2c'].includes(this.advertisement.marketTarget)
    },
    hasProductIntro () {
      return this.advertisement?.timeProductWasMentioned
        && this.advertisement.timeProductWasMentioned > 0
        && this.advertisement?.type === 'video'
    },
    canComputeAspectRatio () {
      return (this.advertisement?.type === 'image' && this.advertisement.image)
        || (this.advertisement?.type === 'video' && this.advertisement.video)
    },
    canTrackBrandAsSpyder () {
      return this.brandAdLibraryId
        && !this.advertisement?.organic
        && (
          this.advertisement?.publisher_platform?.includes('facebook') 
          || this.advertisement?.publisher_platform?.includes('instagram')
          || this.advertisement?.publisher_platform?.includes('messenger')
          || this.advertisement?.publisher_platform?.includes('audience_network')
        )
    },
    isManualUpload () {
      return this.advertisement?.name === 'Manual Upload'
    },
    brandActionsEnabled () {
      return this.enableBrandRouting && !this.isManualUpload && !this.mobileView
    },
    ctaText () {
      if (['dpa', 'carousel', 'dco'].includes(this.advertisement?.type)) {
        return this.advertisement?.cards[this.carouselIndex]?.cta_text
      }
      return this.advertisement?.cta_text
    },
    aspectRatio () {
      if (!this.assetMetadata.width || !this.assetMetadata.height) return null
      const { width, height } = this.assetMetadata
      const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b))
      const divisor = gcd(width, height)
      const simplifiedWidth = width / divisor
      const simplifiedHeight = height / divisor

      // List of common aspect ratios, including smartphone ratios
      const commonAspectRatios = [
        { width: 16, height: 9, name: "16:9 HD Video" },
        { width: 4, height: 3, name: "4:3 Fullscreen" },
        { width: 1, height: 1, name: "1:1 Square" },
        { width: 3, height: 2, name: "3:2 Landscape" },
        { width: 21, height: 9, name: "21:9 Landscape" },
        { width: 9, height: 16, name: "9:16 Vertical" },
        { width: 5, height: 4, name: "5:4 Television" },
        { width: 18, height: 9, name: "18:9 (2:1) Landscape" },
        { width: 19.5, height: 9, name: "19.5:9 Landscape" },
        { width: 20, height: 9, name: "20:9 Landscape" },
        { width: 18.5, height: 9, name: "18.5:9 Landscape" }
      ];

      // Find the closest match using a tolerance for pixel discrepancies
      const tolerance = 0.05;
      let closestMatch = commonAspectRatios.reduce((prev, curr) => {
        const ratioDifference = Math.abs((curr.width / curr.height) - (simplifiedWidth / simplifiedHeight));
        const prevRatioDifference = Math.abs((prev.width / prev.height) - (simplifiedWidth / simplifiedHeight));
        return ratioDifference < prevRatioDifference ? curr : prev;
      });

      // If the difference is within the tolerance, return the common aspect ratio name
      const aspectRatioDifference = Math.abs((closestMatch.width / closestMatch.height) - (simplifiedWidth / simplifiedHeight));
      if (aspectRatioDifference < tolerance) {
        return closestMatch;
      } else {
        const type = simplifiedWidth > simplifiedHeight ? "Landscape" : "Portrait";
        return { width: simplifiedWidth, height: simplifiedHeight, name: `${simplifiedWidth}:${simplifiedHeight} ${type}`};
      }
    },
    productIntro () {
      if (!this.hasProductIntro || !this.assetMetadata.duration) return null
      const videoDuration = Math.round(this.assetMetadata.duration)

      const timeProductWasMentioned = Math.round(this.advertisement.timeProductWasMentioned)
      const percentage = (timeProductWasMentioned / videoDuration) * 100
      const timeMentionedMinutes = Math.floor(timeProductWasMentioned / 60)
      const timeMentionedSeconds = Math.floor(timeProductWasMentioned % 60)
      const minutes = timeMentionedMinutes < 10 ? `0${timeMentionedMinutes}` : timeMentionedMinutes
      const seconds = timeMentionedSeconds < 10 ? `0${timeMentionedSeconds}` : timeMentionedSeconds

      return `${minutes}:${seconds} (${percentage.toFixed()}%)`
    }
  },
  methods: {
    // ------------- EVENT HANDLER FUNCTIONS -------------
    viewAIAnalysis () {
      this.$emit('update:sidebarView', 'ai-analysis')
    },
    // ------------- STRING FORMATTING FUNCTIONS -------------
    formatTime (time) {
      return moment.utc(time).format('MMM DD, YYYY')
    },
    formatEndDate (date) {
      let dateMS
      if (date && !isNaN(date._seconds)) {
        dateMS = date._seconds * 1000
      }
      const formattedDate = moment.utc(dateMS).format('MMM DD, YYYY')
      return formattedDate
    },
    formatRunningDuration (start, end) {
      let endTime
      if (end && !isNaN(end._seconds)) {
        endTime = end._seconds * 1000
      } else {
        endTime = new Date().getTime()
      }
      const startDate = new moment.utc(start)
      const endDate = new moment.utc(endTime)

      return Math.ceil(moment.duration(endDate.diff(startDate)).as('days'))
    },
    // ------------- ICON MAPPER FUNCTIONS -------------
    getFormatIcon (format) {
      switch (format) {
        case 'image': return Icons.ImageFormatIcon
        case 'video': return Icons.VideoFormatIcon
        case 'carousel': return Icons.CarouselFormatIcon
        case 'dco': return DCOIcon
        default: return Icons.OtherFormatIcon
      }
    },
    getNicheIcon (niche) {
      switch (niche) {
        case 'Accessories': return Icons.AccessoriesNicheIcon
        case 'Alcohol': return Icons.AlcoholNicheIcon
        case 'App/Software': return Icons.SoftwareNicheIcon
        case 'Automotive': return Icons.AutomotiveNicheIcon
        case 'Beauty': return Icons.BeautyNicheIcon
        case 'Book/Publishing': return Icons.BookNicheIcon
        case 'Business/Professional': return Icons.BusinessNicheIcon
        case 'Charity/NFP': return Icons.CharityNicheIcon
        case 'Education': return Icons.EducationNicheIcon
        case 'Entertainment': return Icons.EntertainmentNicheIcon
        case 'Fashion': return Icons.FashionNicheIcon
        case 'Finance': return Icons.FinanceNicheIcon
        case 'Food/Drink': return Icons.FoodNicheIcon
        case 'Games': return Icons.GamesNicheIcon
        case 'Government': return Icons.GovernmentNicheIcon
        case 'Health/Wellness': return Icons.HealthNicheIcon
        case 'Home/Garden': return Icons.HomeNicheIcon
        case 'Insurance': return Icons.InsuranceNicheIcon
        case 'Jewelry/Watches': return Icons.JewelryNicheIcon
        case 'Kids/Baby': return Icons.KidsNicheIcon
        case 'Media/News': return Icons.MediaNicheIcon
        case 'Medical': return Icons.MedicalNicheIcon
        case 'Pets': return Icons.PetsNicheIcon
        case 'Real Estate': return Icons.RealEstateNicheIcon
        case 'Service Business': return Icons.ServiceNicheIcon
        case 'Sports/Outdoors': return Icons.SportsNicheIcon
        case 'Tech': return Icons.TechNicheIcon
        case 'Travel': return Icons.TravelNicheIcon
        case 'Other': return Icons.OtherNicheIcon
        default: return Icons.OtherNicheIcon
      }
    },
    getPlatformIcon (platform) {
      switch (platform) {
        case 'facebook': return Icons.FacebookPlatformIcon
        case 'instagram': return Icons.InstagramPlatformIcon
        case 'tiktok': return Icons.TikTokPlatformIcon
        case 'youtube': return Icons.YouTubePlatformIcon
        case 'linkedin': return Icons.LinkedInPlatformIcon
        case 'messenger': return Icons.MessengerPlatformIcon
        default: return Icons.OtherNicheIcon
      }
    }
  }
}
</script>

<style scoped>
.info-grid {
  display: grid;
  grid-template-columns: 144px auto;
  max-width: 100%;
}
.info-grid div:nth-child(2n) {
  /* By default, grid cells cannot be smaller than their content, so we override this */
  min-width: 0;
}
.brand-options-popup {
  position: absolute;
  top: calc(100% + 4px);
  left: -10px;
  z-index: 100;
}

.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>