<template>
  <div class="relative w-full bg-white flex flex-wrap gap-1">
    <!-- Renders the list of selected tags -->
    <div v-for="(tag, index) in tags" :key="index"
    class="self-center relative flex gap-1 items-center py-0.5 pl-3 pr-1 rounded-3xl whitespace-nowrap"
    :style="{ 
      backgroundColor: tag.color ? colors[tag.color].fill : colors['gray'].fill,
      color: tag.color ? colors[tag.color].stroke : colors['gray'].stroke,
    }">
      <div class="truncate font-medium" :style="{maxWidth: '170px'}">
        {{ tag.name }}
      </div>
      <div class="rounded-full bg-white bg-opacity-0 hover:bg-opacity-80 transition ease-in-out cursor-pointer"
      @click="removeTag(tag)">
        <TagRemoveIcon :stroke="tag.color ? colors[tag.color].stroke : colors['gray'].stroke" />
      </div>
    </div>

    <!-- Add new tag input field -->
    <input id="tags-input" placeholder="Add a Tag..." v-model="searchQuery" autocomplete="off"
    class="px-2.5 py-1.5 outline-none hover:bg-neutral-10 rounded-md transition-colors duration-200 ease-in-out" 
    @focus="() => { showSelectorDropdown(); index.group = 'action'; index.action = 'INPUT'}"
    @keydown.down.prevent="highlightNext"
    @keydown.up.prevent="highlightPrev"
    @keydown.enter.prevent="selectHighlighted"
    @click.stop/>

    <!-- Tag Selector DROPDOWN -->
    <div v-show="isSelectorDropdownVisible" id="tag-selector-dropdown" v-on-clickaway="() => {hideSelectorDropdown(); resetIndex();}"
    class="flex flex-col w-full bg-white rounded-xl py-1 z-50">
      <div v-if="dropdownIsPopulated" class="relative w-full">
        <div class="tags-dropdown-list flex flex-col w-full px-1 pb-0.5">
          <!-- Render the "+ Create" button if the searched tag doesn't exist -->
          <div v-if="showCreateButton" ref="create-button">
            <div class="flex items-center w-full p-1.5 rounded-md bg-white hover:bg-neutral-25 
            transition-colors ease-in-out no-select cursor-pointer gap-2"
            :class="{ 'bg-neutral-50': index[index.group] === 'CREATE'}" @mouseenter="setIndexInput"
            @click="() => { createOrAddTag(searchQuery); }">
              <div v-if="isCreatingTag" class="w-5">
                <BaseLoadingSpinner small />
              </div>
              <PlusIcon v-else className="w-5" />
              <div class="text-text-normal relative right-px">
                Create
              </div>
              <div>
                '{{ searchQuery }}'
              </div>
            </div>
          </div>
          <!-- Render the list of the user's previously created tags, relevant to their search -->
          <div v-for="(tag, i) in searchResults.items" :key="i" :ref="`tag-${i}`"
          class="flex items-center w-full gap-2 p-1.5 rounded-md bg-white hover:bg-neutral-25 transition-colors ease-in-out
          no-select cursor-pointer duration-75"
          :class="{ 'bg-neutral-50 z-50': index[index.group] === i }"
          @click="createOrAddTag(tag)" @mouseenter="setIndexInput">
            <BaseLoadingSpinnerCircle v-if="addingTagName === tag.name" class="text-icon-normal" />
            <div v-else class="w-5">
              <TagIcon :stroke="tag.color ? colors[tag.color].stroke : colors['gray'].stroke"
              :fill="tag.color ? colors[tag.color].fill : colors['gray'].fill"/>
            </div>
            <div class="text-text-muted truncate">
              {{  tag.name }}
            </div>
          </div>
        </div>
        <div v-if="searchResults.items.length > (showCreateButton ? 6 : 7)" class="dropdown-fade-overlay pointer-events-none" />
      </div>
      <!-- Clicking this will bring up the modal -->
      <div class="w-full px-1"
      :class="{'border-t border-neutral-50 mt-0.5': dropdownIsPopulated}">
        <div class="w-full text-center py-1.5 text-text-muted font-medium
        rounded-md hover:bg-neutral-25 transition-colors ease-in-out cursor-pointer no-select"
        :class="{'mt-1': dropdownIsPopulated}"
        @click="() => {openEditTagsModal(); hideSelectorDropdown();}">
          Edit Tags
        </div>
      </div>
    </div>
    <!-- Edit Tags MODAL -->
    <EditTagsModal v-if="editTagsModalOpen" 
    :advertisement="advertisement"
    @close="editTagsModalOpen = false" 
    @remove="updateAfterDelete" 
    @edit="applyTagEditLocal"/>
  </div>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import { mapActions, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import EditTagsModal from '@/components/advertisements/DetailsDrawerComponents/EditTagsModal.vue'
import TAG_COLORS from '../../../utils/TagColors'
import TagIcon from '@/components/globals/Icons/TagIcon.vue'
import PlusIcon from '@/components/globals/Icons/PlusIcon.vue'
import TagRemoveIcon from '@/components/globals/Icons/TagRemoveIcon.vue'
import BaseLoadingSpinner from '../../globals/BaseLoadingSpinner.vue'

const DEFAULT_INDEX = {
  group: null, // null || 'action' || 'tag' -- Acts as a flag to determine which index is being interacted with
  action: "INPUT", // 'INPUT' || 'CREATE'
  tag: -1 // tag index [0, 1, ...] (-1 if no tags are selected)
};
const INPUT_INDEX = {
  group: 'action',
  action: 'INPUT',
  tag: -1
};

export default {
  name: 'Tags',
  mixins: [clickaway],
  components: {
    TagIcon,
    PlusIcon,
    TagRemoveIcon,
    EditTagsModal,
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    bulkEditMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tags: [], // Will reflect the tags assigned to the advertisement
      searchQuery: '',
      isSelectorDropdownVisible: false,
      index: { ...DEFAULT_INDEX }, // The index of the currently highlighted item (arrow keys)
      editTagsModalOpen: false,
      colors: TAG_COLORS,
      // Loading states
      isCheckingExistence: false,
      isCreatingTag: false,
      addingTagName: null,
    }
  },
  computed: {
    ...mapGetters('TagsModule', ['getTags']),
    ...mapGetters('AuthModule', ['getTeam']),
    searchResults () {
      // Get all the strings that have the query string as a substring
      let items = this.getTags.filter(tag => 
        tag.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );

      // Sort by the closeness of the match (closer = lower index)
      items.sort((a, b) => {
        const ratioA = this.searchQuery.length / a.name.length;
        const ratioB = this.searchQuery.length / b.name.length;

        return ratioB - ratioA;
      });

      // Determine if the search query is an exact match to an existing tag
      let exactMatch = items.length > 0 && items[0].name.toLowerCase() === this.searchQuery.toLowerCase();

      // Filter out tags that are already added to the advertisement
      items = items.filter(tag => !this.tags.some(t => t.name === tag.name));

      return { items, exactMatch };
    },
    showCreateButton () {
      return this.searchQuery !== '' && !this.searchResults.exactMatch;
    },
    dropdownIsPopulated () {
      return this.searchResults.items.length > 0 || this.showCreateButton;
    }
  },
  watch: {
    async advertisement () {
      if (this.bulkEditMode) return;

      if (!this.advertisement.tags) {
        this.advertisement.tags = []
      }
      const adTags = this.advertisement.tags
      this.tags = await FirebaseAPI.Tags.getTagsArray(adTags)
    },
    searchQuery () {
      // When the search query changes, set the index to the input
      this.setIndexInput();
    }
  },
  async mounted () {
    if (this.bulkEditMode) return;

    if (!this.advertisement.tags) {
      this.advertisement.tags = []
    }
    const adTags = this.advertisement.tags
    this.tags = await FirebaseAPI.Tags.getTagsArray(adTags)
  },
  methods: {
    ...mapActions('TagsModule', ['fetchTags']),
    async createOrAddTag (tag) {
      const tagName = tag.name || tag
      const teamId = this.getTeam ? this.getTeam.id : null

      // Check if loading or if ad already has this tag
      if (this.isCheckingExistence || this.isCreatingTag || this.addingTagName || this.tags.some(t => t.name === tag.name)) {
        return
      }

      // Check if tag exists
      this.isCheckingExistence = true
      const existingTags = await FirebaseAPI.Tags.getTagByName(tagName, teamId)
      this.isCheckingExistence = false

      if (existingTags.length) { // Add an existing tag to the advertisement

        if (this.bulkEditMode) {
          this.tags.push(existingTags[0])
          this.$emit('update:bulkSelectedTags', this.tags)
          return
        }

        this.addingTagName = tagName
        const updatedTags = this.advertisement.tags
          ? [...this.advertisement.tags, existingTags[0].id]
          : [existingTags[0].id]

        try {
          await FirebaseAPI.Advertisements.update(this.advertisement.id, {
            tags: updatedTags
          })
          this.advertisement.tags.push(existingTags[0].id)
          this.fetchTags()
        } catch (error) {
          // Only show an alert if adding fails, to prevent spamming users with alerts
          console.error('Error adding tag to advertisement:', error)
          this.$showAlert({
            message: error,
            type: 'error'
          })
        } finally {
          this.addingTagName = null
        }
      } else { // Create Tag
        this.isCreatingTag = true
        try {
          const createdTag = await FirebaseAPI.Tags.create(tag, teamId)

          if (this.bulkEditMode) {
            this.tags.push(createdTag)
            this.$emit('update:bulkSelectedTags', this.tags)
            return
          }

          const updatedTags = this.advertisement.tags
            ? [...this.advertisement.tags, createdTag.id]
            : [createdTag.id]
          await FirebaseAPI.Advertisements.update(this.advertisement.id, {
            tags: updatedTags
          })
          this.advertisement.tags.push(createdTag.id)
          this.fetchTags()
          this.$showAlert({
            message: 'Tag created successfully',
            type: 'success'
          })
        } catch (error) {
          console.error('Error creating tag:', error)
          this.$showAlert({
            message: error,
            type: 'error'
          })
        } finally {
          this.isCreatingTag = false
          this.searchQuery = ''
        }
      }

      // track event Tag Added
      window.analytics.track('Tag Added', {
        tagAdded: true,
        tag: tagName,
        advertisement: this.advertisement.name,
        platform: this.advertisement.publisher_platform,
        adNiche: this.advertisement.niches,
        adFormat: this.advertisement.type
      })

      if (!this.bulkEditMode) this.tags = await FirebaseAPI.Tags.getTagsArray(this.advertisement.tags)
    },
    async removeTag (tag) {
      const updatedTags = this.tags.filter((t) => t.id !== tag.id)
      this.tags = updatedTags

      if (this.bulkEditMode) {
        this.$emit('update:bulkSelectedTags', this.tags)
        return
      }

      try {
        this.advertisement.tags = this.advertisement.tags.filter(
          (tId) => tId !== tag.id
        )
        await FirebaseAPI.Advertisements.update(this.advertisement.id, {
          tags: this.advertisement.tags
        })
      } catch (error) {
        console.error('Error removing tag from advertisement:', error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
    },
    updateAfterDelete (tag) {
      // Remove a tag from the advertisement/bulkEdit if it was deleted in the modal
      this.tags = this.tags.filter((t) => t.id !== tag.id)
      this.$emit('tagDeleted', tag.id)
      if (this.bulkEditMode) this.$emit('update:bulkSelectedTags', this.tags)
    },
    applyTagEditLocal (tagId, updatedTag) {
      // Get the tag and index this.tags and update it to mirror the changes made in the modal
      const tagIndex = this.tags.findIndex((t) => t.id === tagId)
      if (tagIndex !== -1) {
        const oldTag = this.tags[tagIndex]
        this.tags = [
          ...this.tags.slice(0, tagIndex),
          { ...oldTag, ...updatedTag },
          ...this.tags.slice(tagIndex + 1)
        ]
      }
      this.$emit('update:tableViewTags', tagId, updatedTag)
      if (this.bulkEditMode && tagIndex !== -1) this.$emit('update:bulkSelectedTags', this.tags)
    },
    showSelectorDropdown () { this.isSelectorDropdownVisible = true; },
    hideSelectorDropdown () { this.isSelectorDropdownVisible = false; },
    openEditTagsModal () { this.editTagsModalOpen = true; },

    // ======== KEYBOARD NAVIGATION ========
    highlightNext () {
      if (!this.index.group) return;
      if (this.index.group === 'action') {
        const createButtonVisible = this.searchQuery !== '' && !this.searchResults.exactMatch;
        if (this.index.action === 'INPUT' && createButtonVisible) {
          this.index.action = 'CREATE';
          this.scrollToItem('create-button')
        } else if (this.searchResults.items.length > 0) {
          this.index.group = 'tag';
          this.index.tag = 0;
          this.scrollToItem('tag-0')
        }
      } else if (this.index.tag < this.searchResults.items.length - 1) {
        this.index.tag++;
        this.scrollToItem(`tag-${this.index.tag}`)
      }
    },
    highlightPrev () {
      if (!this.index.group) return;
      if (this.index.group === 'tag') {
        if (this.index.tag > 0) {
          this.index.tag--;
          this.scrollToItem(`tag-${this.index.tag}`)
        } else {
          this.index.group = 'action';
          if (this.showCreateButton) {
            this.index.action = 'CREATE';
            this.scrollToItem('create-button')
          } else {
            this.index.action = 'INPUT';
          }
          this.index.tag = -1;
        }
      } else if (this.index.action === 'CREATE') {
        this.index.action = 'INPUT';
      }
    },
    resetIndex () { 
      if (this.index.group) 
        this.index = { ...DEFAULT_INDEX }; 
    },
    setIndexInput () { 
      if (this.index.action !== 'INPUT' || this.index.group !== 'action')
        this.index = { ...INPUT_INDEX };
    },
    selectHighlighted () {
      if (!this.index.group) return;
      if (this.index.group === 'action') {
        if (this.index.action === 'CREATE' && this.searchQuery !== '') {
          this.createOrAddTag(this.searchQuery);
        }
      } else if (this.index.group === 'tag') {
        this.createOrAddTag(this.searchResults.items[this.index.tag]);
      }
    },
    scrollToItem (ref) {
      this.$nextTick(() => {
        const elm = ref === 'create-button' ? this.$refs[ref] : this.$refs[ref][0];
        if (elm) elm.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      })
    }
    // ====================================
  }
}
</script>

<style scoped>
  /* Setting flex basis = min width allows us to shrink THEN wrap */
  #tags-input {
    flex: 1 1 110px;
    min-width: 110px;
  }
  /* We need custom CSS rules to transition pseudo-element colors */
  #tags-input::placeholder {
    transition: color 150ms ease-in-out;
    color: #5E6678; /* colors.text.normal */

    /* Body/Small */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  #tags-input:focus::placeholder {
    color: #808899; /* colors.text.subdued */
  }
  #tag-selector-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    box-shadow: 0px 24px 32px -12px rgba(53, 57, 75, 0.12), 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.04), 0px 1px 2px 0px rgba(162, 172, 186, 0.16), 0px 0px 0px 1px #EBEFF3;
  }
  .tag-del-btn {
    position: relative;
    bottom: 1px;
  }
  .tags-dropdown-list {
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: 260px;
  }
  .dropdown-fade-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffffb9 100%);
  }

  /* class is applied to elements we don't want users to be able to select text on */
  .no-select {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
</style>
